import React, { useEffect } from 'react'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { TFunction, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

interface MainMenuMobileProps {
  closeCanvas: Function
  location: WindowLocation | { pathname: string }
}

export default function MainMenuMobile({ closeCanvas }: MainMenuMobileProps): JSX.Element {
  const { t } = useTranslation()
  const { originalPath } = useI18next()

  return (
    <aside id="sidebar-mobile" uk-offcanvas="flip: true">
      <div className="uk-offcanvas-bar">
        <button className="uk-offcanvas-close" type="button" uk-close=""></button>
        <ul className="uk-nav uk-nav-default">
          {originalPath.match('/investor') ? (
            <MobileInvestorMenu originalPath={originalPath} closeCanvas={closeCanvas} t={t} />
          ) : (
            <MobileBusinessMenu originalPath={originalPath} closeCanvas={closeCanvas} t={t} />
          )}
        </ul>
      </div>
    </aside>
  )
}

interface SubMenuProps {
  originalPath: string
  closeCanvas: Function
  t: TFunction
}

export const MobileBusinessMenu = ({ originalPath, closeCanvas, t }: SubMenuProps) => {
  const [currentMenu, setcurrentMenu] = React.useState(
    typeof window !== 'undefined' && localStorage.getItem('currentMenu')
  )

  useEffect(() => {
    typeof window !== 'undefined' && currentMenu && localStorage.setItem('currentMenu', currentMenu)
  }, [currentMenu])

  return (
    <>
      <li className="uk-nav-header">{t(`business`)}</li>
      <li className={originalPath === `/` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            closeCanvas().then(() => navigate(`/`))
          }}
        >
          {t(`home`)}
        </a>
      </li>

      <li className={originalPath === `/products/` ? `uk-parent uk-active` : undefined}>
        <a className="uk-text-capitalize" href="#">
          {t(`Business Communication`)}
        </a>
        <ul className="uk-nav-sub">
          <li className={currentMenu === 'jascloud' ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('jascloud')
                closeCanvas().then(() => navigate(`/products/jascloud`))
              }}
            >
              Jascloud
            </a>
          </li>
          <li className={currentMenu === `whatsapp-business` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('whatsapp-business')
                closeCanvas().then(() => navigate(`/products/whatsapp-business`))
              }}
            >
              Whatsapp Business
            </a>
          </li>
          <li className={currentMenu === `jasmeet` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('jasmeet')
                closeCanvas().then(() => navigate(`/products/jasmeet`))
              }}
            >
              Jasmeet
            </a>
          </li>
          <li className={currentMenu === `messages` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('messages')
                closeCanvas().then(() => navigate(`/products/whatsapp-business`))
              }}
            >
              {t('Messages')}
            </a>
          </li>
          <li className={currentMenu === `voip` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('voip')
                closeCanvas().then(() => navigate(`/products/jascloud#voip`))
              }}
            >
              {t('Phone')}
            </a>
          </li>
          <li className={currentMenu === `video` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('video')
                closeCanvas().then(() => navigate(`/products/jasmeet`))
              }}
            >
              {t('Video')}
            </a>
          </li>
        </ul>
      </li>

      <li className={originalPath === `/products/` ? `uk-parent uk-active` : undefined}>
        <a className="uk-text-capitalize" href="#">
          {t(`Customer Experience`)}
        </a>
        <ul className="uk-nav-sub">
          <li className={currentMenu === `sakti` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('sakti')
                closeCanvas().then(() => navigate(`/products/tos-omnichannel`))
              }}
            >
              TOSchat
            </a>
          </li>
          <li className={currentMenu === `crm` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('crm')
                closeCanvas().then(() => navigate(`/products/jascloud#crm`))
              }}
            >
              {t('CRM')}
            </a>
          </li>
          <li className={currentMenu === `contact-center` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('contact-center')
                closeCanvas().then(() => navigate(`/products/jascloud#contact-center`))
              }}
            >
              {t('Contact Center')}
            </a>
          </li>
          <li className={currentMenu === `social media` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('social media')
                closeCanvas().then(() => navigate(`/products/tos-omnichannel#omni-channel`))
              }}
            >
              {t('Social Media')}
            </a>
          </li>
          <li className={currentMenu === `chat` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('chat')
                closeCanvas().then(() => navigate(`/products/tos-omnichannel#chat`))
              }}
            >
              {t('Chat')}
            </a>
          </li>
          <li className={currentMenu === `ticketing` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                closeCanvas().then(() => navigate(`/products/jascloud#crm`))
              }}
            >
              {t('Ticketing')}
            </a>
          </li>
        </ul>
      </li>

      <li className={originalPath === `/products/` ? `uk-parent uk-active` : undefined}>
        <a className="uk-text-capitalize" href="#">
          {t(`Government Services`)}
        </a>
        <ul className="uk-nav-sub">
          <li className={currentMenu === `112` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('112')
                closeCanvas().then(() => navigate(`/products/emergency-call`))
              }}
            >
              112
            </a>
          </li>
          <li className={currentMenu === `business network` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('business network')
                closeCanvas().then(() => navigate(`/products/business-network`))
              }}
            >
              {t('Business Network')}
            </a>
          </li>
          <li className={currentMenu === `cyber security` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('cyber security')
                closeCanvas().then(() => navigate(`/products/business-network#cyber-security`))
              }}
            >
              {t('Cyber Security')}
            </a>
          </li>
          <li className={currentMenu === `business outsourcing` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('business outsourcing')
                closeCanvas().then(() => navigate(`/products/business-outsourcing`))
              }}
            >
              {t('Business Outsourcing')}
            </a>
          </li>
          {/* <li className={currentMenu === `customization` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('customization')
                closeCanvas().then(() => navigate(`/products/jasgrid`))
              }}
            >
              {t('Customization')}
            </a>
          </li> */}
        </ul>
      </li>

      <li className={currentMenu === `ecosystem` ? `uk-parent uk-active` : undefined}>
        <a className="uk-text-capitalize" href="#">
          {t(`Ecosystem`)}
        </a>
        <ul className="uk-nav-sub">
          <li className={currentMenu === `marketplace` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('marketplace')
                closeCanvas().then(() => navigate(`/products/jasgrid`))
              }}
            >
              {t('Jast Marketplace')}
            </a>
          </li>
          <li className={currentMenu === `developer portal` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('developer portal')
                closeCanvas().then(() => navigate(`/developer-portal`))
              }}
            >
              {t('Developer Portal')}
            </a>
          </li>
          <li className={currentMenu === `jasgrid` ? `uk-active` : undefined}>
            <a
              className="uk-text-capitalize"
              href="#"
              onClick={(e): void => {
                e.preventDefault()
                setcurrentMenu('jasgrid')
                closeCanvas().then(() => navigate(`/products/jasgrid`))
              }}
            >
              {t('Jasgrid')}
            </a>
          </li>
        </ul>
      </li>

      <li className={currentMenu === `solutions` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            setcurrentMenu('solutions')
            closeCanvas().then(() => navigate(`/solutions`))
          }}
        >
          {t(`Solutions`)}
        </a>
      </li>

      <li className={currentMenu === `about` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            setcurrentMenu('about')
            closeCanvas().then(() => navigate(`/about#history`))
          }}
        >
          {t(`about`)}
        </a>
      </li>

      <li className={currentMenu === `blog` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            setcurrentMenu('blog')
            closeCanvas().then(() => navigate(`/blog`))
          }}
        >
          {t(`blog`)}
        </a>
      </li>

      <li className={currentMenu === `contact` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            setcurrentMenu('contact')
            closeCanvas().then(() => navigate(`/contact`))
          }}
        >
          {t(`contact us`)}
        </a>
      </li>

      {/* <li className="uk-nav-divider"></li> */}
    </>
  )
}

export const MobileInvestorMenu = ({ originalPath, closeCanvas, t }: SubMenuProps) => {
  return (
    <>
      <li className="uk-nav-header">Investor</li>
      <li className={originalPath === `/investor/` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            closeCanvas().then(() => navigate(`/investor`))
          }}
        >
          {t(`home`)}
        </a>
      </li>
      <li className={originalPath === `/investor/about/` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            closeCanvas().then(() => navigate(`/investor/about#history`))
          }}
        >
          {t(`about`)}
        </a>
        <div className="uk-navbar-dropdown">
          <ul className="uk-nav uk-navbar-dropdown-nav">
            <li className="uk-active">
              <a href="#">History</a>
            </li>
            <li>
              <a href="#">Visi &amp; Mission</a>
            </li>
            <li>
              <a href="#">Organization Structure</a>
            </li>
            <li>
              <a href="#">Board of Commissioners</a>
            </li>
            <li>
              <a href="#">Board of Directors</a>
            </li>
            <li>
              <a href="#">Company Secretary</a>
            </li>
            <li>
              <a href="#">Rewards</a>
            </li>
            <li>
              <a href="#">Supporting Institutions</a>
            </li>
            <li>
              <a href="#">Deed of Company</a>
            </li>
            <li>
              <a href="#">Subsidiary</a>
            </li>
          </ul>
        </div>
      </li>
      <li className={originalPath === `/investor/report/` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            closeCanvas().then(() => navigate(`/investor/report`))
          }}
        >
          {t(`report`)}
        </a>
        <div className="uk-navbar-dropdown">
          <ul className="uk-nav uk-navbar-dropdown-nav">
            <li className="uk-active">
              <a href="#anual-report">Annual Report</a>
            </li>
            <li>
              <a href="#financial-report">Financial Report</a>
            </li>
          </ul>
        </div>
      </li>
      <li className={originalPath === `/investor/stock/` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            closeCanvas().then(() => navigate(`/investor/stock`))
          }}
        >
          {t(`stock`)}
        </a>
      </li>
      <li className={originalPath === `/investor/rups/` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            closeCanvas().then(() => navigate(`/investor/rups`))
          }}
        >
          {t(`rups`)}
        </a>
      </li>
      <li className={originalPath === `/investor/news/` ? `uk-active` : undefined}>
        <a
          className="uk-text-capitalize"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            closeCanvas().then(() => navigate(`/investor/news`))
          }}
        >
          {t(`news`)}
        </a>
      </li>
    </>
  )
}
