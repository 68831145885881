import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { useUiKit } from '../hooks/uikit'
import { useEffect } from 'react'
import { useLocation } from '@reach/router'

type FooterDataType = {
  logoJast: {
    id: string
    publicURL: string
  }
  logoIDX: {
    id: string
    publicURL: string
  }
  logoApjii: {
    id: string
    publicURL: string
  }
  supportElement: {
    id: string
    publicURL: string
  }
}

const Footer: React.FC = (props) => {
  const { t, i18n } = useTranslation()
  const uikitHandler = useUiKit()
  const location = useLocation()

  const data: FooterDataType = useStaticQuery(graphql`
    query FooterQuery {
      logoJast: file(relativePath: { eq: "images/logo-jast.svg" }) {
        id
        publicURL
      }
      logoIDX: file(relativePath: { eq: "images/logo-idx.png" }) {
        id
        publicURL
      }
      logoApjii: file(relativePath: { eq: "images/logo-apjii.png" }) {
        id
        publicURL
      }
    }
  `)

  useEffect(() => {
    if (location.pathname.split('/').pop() !== 'contact')
      setTimeout(() => uikitHandler.modal('#modal-promo').show(), 5000)
  }, [])

  return (
    <>
      <footer className="tm-footer-background">
        <div className="uk-container">
          <section className="uk-section">
            <img src={data.logoJast.publicURL} width="140" height="100%" alt="Logo JAST" uk-img="" />
            <div className="uk-margin-top" uk-grid="">
              <div className="uk-width-1-3@m">
                <p>
                  {i18n.language === 'en' &&
                    'Jasnita is an award winning cloud contact center and omni channel software provider in Indonesia. We are the leading cloud communications platform with 25 years experience in VOIP and Business Networking.'}
                  {i18n.language === 'id' &&
                    'Jasnita adalah pemenang penghargaan cloud contact center dan penyedia perangkat lunak saluran omni di Indonesia. Kami adalah platform komunikasi cloud terkemuka dengan pengalaman 25 tahun di VOIP dan Jaringan Bisnis.'}
                </p>
                <ul className="uk-list">
                  <li></li>
                  <li>
                    <a href="https://g.page/jasnitatel?share" target="_blank" rel="noreferrer noopener">
                      E-Trade Building 7TH fl Jl. K.H. Wahid Hasyim No. 55 Jakarta-10350, INDONESIA.
                    </a>
                  </li>
                  <li>
                    <span className="uk-margin-right" uk-icon="receiver"></span>
                    <a href="tel:+622128565288">(021)-2856-5288</a>
                  </li>
                  <li>
                    <span className="uk-margin-right" uk-icon="mail"></span>
                    <a href="mailto:marketing@jasnita.co.id">sales@jasnita.co.id</a>
                  </li>
                  <li className="uk-flex">
                    <a
                      className="uk-margin-right"
                      href="https://www.facebook.com/jasnitatel"
                      uk-icon="icon: facebook"
                      aria-label="facebook"
                    ></a>
                    <a
                      className="uk-margin-right"
                      href="https://www.instagram.com/jasnita.id/"
                      uk-icon="icon: instagram"
                      aria-label="instagram"
                    ></a>
                    <a
                      className="uk-margin-right"
                      href="https://twitter.com/jasnitatel"
                      uk-icon="icon: twitter"
                      aria-label="twitter"
                    ></a>
                    <a
                      className="uk-margin-right"
                      href="https://www.linkedin.com/company/pt-jasnita-telekomindo/"
                      uk-icon="icon: linkedin"
                      aria-label="linkedin"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        {/* Whatsapp Button */}
        <div className="phone-call cbh-phone cbh-green cbh-show  cbh-static" id="clbh_phone_div">
          <a
            id="WhatsApp-button"
            href="https://api.whatsapp.com/send/?phone=6281119015555&text=Hai+Jasnita%2C+Saya+tertarik+dengan+produk+anda.+Saya+tau+dari+website+https://jasnita.com/&type=phone_number&app_absent=0"
            target="_blank"
            className="phoneJs"
            title="Jasnita Telekomindo"
          >
            <div className="cbh-ph-circle"></div>
            <div className="cbh-ph-circle-fill"></div>
            <div className="cbh-ph-img-circle1"></div>
          </a>
        </div>
        {/* <img src={supportElement.publicURL} style={{ position: 'absolute', right: 20, top: 80 }} alt="" uk-img="" /> */}
      </footer>
      {/* Modal */}
      <div id="modal-promo" className="uk-flex-top" uk-modal="">
        <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
          <button className="uk-modal-close-outside" type="button" uk-close=""></button>
          <Link to="/contact">
            <iframe src="https://drive.google.com/file/d/1E4EP-F2d3Ur6Vbdk8BfMreJ5bvmFM-Xu/preview" width="640" height="480" allow="autoplay"></iframe>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Footer
