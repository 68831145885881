import UIkit from 'uikit'
import { useState, useEffect } from 'react'

const Icons = require('uikit/dist/js/uikit-icons.min')

// UIkit.use(Icons)

export function useUiKit(): typeof UIkit {
  const [uikithandler] = useState<typeof UIkit>({...UIkit})

  uikithandler.use(Icons)

  // console.log('uikit version: ' + uiKitHandler.version)

  return uikithandler
}
