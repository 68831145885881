import React from 'react'
import { useUiKit } from '../hooks/uikit'
import Header from './header'
import Footer from './footer'

import '../styles/main.scss'
import '../styles/general.scss'

interface LayoutPropsType {}

const Layout: React.FC<LayoutPropsType> = (props) => {
  const uikithandler = useUiKit()

  return (
    <>
      <Header UIkit={uikithandler} />
      <main>{props.children}</main>
      <Footer />
    </>
  )
}

export default Layout
