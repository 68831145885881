import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useLocation } from '@reach/router'
import UIkit from 'uikit'
import MainMenu from './main-menu'
import MainMenuMobile from './main-menu-mobile'

interface HeaderProps {
  UIkit: typeof UIkit
}

type HeaderDataType = {
  logoDark: {
    id: string
    publicURL: string
  }
  logoLight: {
    id: string
    publicURL: string
  }
  flagIndonesia: {
    id: string
    publicURL: string
  }
  flagUsa: {
    id: string
    publicURL: string
  }
}

function Header({ UIkit }: HeaderProps): JSX.Element {
  const { t } = useTranslation()
  const location = useLocation()
  const { changeLanguage, originalPath, language } = useI18next()

  const data: HeaderDataType = useStaticQuery(graphql`
    query HeaderQuery {
      logoDark: file(relativePath: { eq: "images/logo-jasnita-white.svg" }) {
        id
        publicURL
      }
      logoLight: file(relativePath: { eq: "images/logo-jasnita.svg" }) {
        id
        publicURL
      }
      flagIndonesia: file(relativePath: { eq: "images/flag-indonesia.svg" }) {
        id
        publicURL
      }
      flagUsa: file(relativePath: { eq: "images/flag-usa.svg" }) {
        id
        publicURL
      }
    }
  `)

  const setFlag = () => {
    switch (language) {
      case 'id':
        return data.flagIndonesia.publicURL
      case 'en':
        return data.flagUsa.publicURL
      default:
        return data.flagUsa.publicURL
    }
  }

  const selectedLanguage = () => {
    switch (language) {
      case 'id':
        return 'Indonesia'
      case 'en':
        return 'English'
      default:
        return 'English'
    }
  }

  const closeCanvas = async (): Promise<void> => {
    await UIkit.offcanvas(`#sidebar-mobile`).hide()
  }

  return (
    <>
      <MainMenuMobile closeCanvas={closeCanvas} location={location} />
      <header className="tm-header-overlay" uk-header="">
        <div className="tm-navbar-nav-top uk-container uk-light uk-preserve-color">
          <nav className="uk-navbar" uk-navbar="">
            <div className="uk-navbar-left">
              <ul className="uk-navbar-nav">
                <li className={originalPath.split('/')[1] !== `investor` ? `uk-active` : undefined}>
                  <Link
                    to="/"
                    onClick={() => {
                      // remove current menu from local storage
                      localStorage.setItem('currentMenu', '')
                    }}
                  >
                    {t('Business')}
                  </Link>
                </li>
                <li className={originalPath.split('/')[1] === `investor` ? `uk-active` : undefined}>
                  <Link
                    to="/investor"
                    onClick={() => {
                      // remove current menu from local storage
                      localStorage.setItem('currentMenu', '')
                    }}
                  >
                    {t('Investor')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="uk-navbar-right">
              <ul className="uk-navbar-nav">
                <li className="uk-visible@m">
                  <a href="tel:+622128565288">
                    Hotline:&nbsp;<strong>(021)-2856-5288</strong>
                  </a>
                </li>
                <li>
                  <div className="uk-flex">
                    <img data-src={setFlag()} width="40" height="29" alt="flag" uk-img="" />
                    <a href="#" className="uk-navbar-toggle uk-icon uk-navbar-toggle-icon" id="btn-language">
                      {selectedLanguage()}
                    </a>
                    <div className="uk-navbar-dropdown">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className={language === `en` ? `uk-active` : undefined}>
                          <a
                            href="#"
                            onClick={(e): void => {
                              changeLanguage(`en`)
                            }}
                          >
                            English
                          </a>
                        </li>
                        <li className={language === `id` ? `uk-active` : undefined}>
                          <a
                            href="#"
                            onClick={(e): void => {
                              changeLanguage(`id`)
                            }}
                          >
                            Bahasa Indonesia
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <hr className="uk-margin-remove" />
        </div>

        <div
          className="uk-preserve-color"
          uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-light; top: 200"
        >
          <div className="uk-navbar-container">
            <div className="uk-container">
              <nav className="uk-navbar" uk-navbar="dropbar: false;">
                <div className="uk-navbar-left">
                  <a className="uk-navbar-item uk-logo" href="https://jasnita.com/">
                    <img
                      data-src={data.logoLight.publicURL}
                      width="140"
                      height="40"
                      alt="Logo Jasnita White"
                      uk-img=""
                    />
                    <img
                      className="uk-logo-inverse"
                      data-src={data.logoDark.publicURL}
                      width="140"
                      height="40"
                      alt="Logo Jasnita Dark"
                      uk-img=""
                    />
                  </a>
                </div>

                <div className="uk-navbar-right">
                  <a className="uk-hidden@m" href="tel:+622128565288" style={{ fontSize: `0.7rem` }}>
                    <strong>(021)-2856-5288</strong>
                  </a>
                  <a
                    className="uk-navbar-toggle uk-icon uk-navbar-toggle-icon uk-hidden@m"
                    href="#sidebar-mobile"
                    uk-toggle=""
                    aria-label="main menu"
                  >
                    <span uk-icon="menu"></span>
                  </a>
                  <MainMenu />
                  <Link
                    className="uk-button uk-button-small uk-button-primary uk-margin-left uk-visible@m"
                    to="/contact"
                  >
                    {t('Contact Us')}
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
