import React, { useEffect, useState, useRef } from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useLocation } from '@reach/router'
import { useUiKit } from '../hooks/uikit'

// Load Page Contents
import contentEn from '../../content/pages/investor/about.en.yaml'
import contentId from '../../content/pages/investor/about.id.yaml'

type MainMenuQueryType = {
  businessCommunicationIcon: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  customerExperienceIcon: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  governmentServiceIcon: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  ecosystemIcon: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  jascloudLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  whatsappBusinessLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  jasmeetLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  omniLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  crmLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  saktiLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  marketplaceLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  developerLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  jasgridLogo: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export default function MainMenu(): JSX.Element {
  const uikit = useUiKit()
  const appMenu = useRef<HTMLDivElement>(null)
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const { originalPath } = useI18next()
  const location = useLocation()

  const data: MainMenuQueryType = useStaticQuery(graphql`
    query MainMenuQuery {
      businessCommunicationIcon: file(relativePath: { eq: "images/menu-icons/menu-icon-business-communication.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      customerExperienceIcon: file(relativePath: { eq: "images/menu-icons/menu-icon-customer-experience.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      governmentServiceIcon: file(relativePath: { eq: "images/menu-icons/menu-icon-goverment-services.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      ecosystemIcon: file(relativePath: { eq: "images/menu-icons/menu-icon-ecosystem.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      jascloudLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-jascloud.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      whatsappBusinessLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-whatsapp-business.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      jasmeetLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-jasmeet.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      omniLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-omni.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      crmLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-crm.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      saktiLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-sakti.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      marketplaceLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-marketplace.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      developerLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-developer.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      jasgridLogo: file(relativePath: { eq: "images/menu-icons/menu-logo-jasgrid.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
    }
  `)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  const onLinkClicked = () => {
    appMenu.current?.classList.remove('uk-open')
  }

  const path = process.env.NODE_ENV === `development` ? 1 : 2

  return (
    <>
      <ul
        className="uk-navbar-nav uk-visible@m"
        style={originalPath.split('/')[1] !== `investor` ? { display: `none` } : undefined}
      >
        <li className={originalPath === `/investor` ? `uk-active` : undefined}>
          <Link to="/investor">{t('Home')}</Link>
        </li>
        <li className={originalPath === `/investor/about` ? `uk-active` : undefined}>
          <Link to="/investor/about">{t('About')}</Link>
          <div className="uk-navbar-dropdown">
            <ul className="uk-nav uk-navbar-dropdown-nav">
              <li className={location.hash === '#history' ? 'uk-active' : undefined}>
                <Link to="/investor/about#history">{content.history.title}</Link>
              </li>
              <li className={location.hash === '#vision-and-mission' ? 'uk-active' : undefined}>
                <Link to="/investor/about#vision-and-mission">{content.vision_and_mission.title}</Link>
              </li>
              <li className={location.hash === '#organization-structure' ? 'uk-active' : undefined}>
                <Link to="/investor/about#organization-structure">{content.organization_structure.title}</Link>
              </li>
              <li className={location.hash === '#board-of-comissioners' ? 'uk-active' : undefined}>
                <Link to="/investor/about#board-of-comissioners">{content.board_of_comissioners.title}</Link>
              </li>
              <li className={location.hash === '#board-of-directors' ? 'uk-active' : undefined}>
                <Link to="/investor/about#board-of-directors">{content.board_of_directors.title}</Link>
              </li>
              <li className={location.hash === '#supporting-institution' ? 'uk-active' : undefined}>
                <Link to="/investor/about#supporting-institution">{content.supporting_institution.title}</Link>
              </li>
            </ul>
          </div>
        </li>
        <li className={originalPath === `/investor/report` ? `uk-active` : undefined}>
          <Link to="/investor/report">{t('Report')}</Link>
        </li>
        <li className={originalPath === `/investor/stock` ? `uk-active` : undefined}>
          <Link to="/investor/stock">{t('Stock')}</Link>
        </li>
        <li className={originalPath === `/investor/rups` ? `uk-active` : undefined}>
          <Link to="/investor/rups">{t('Rups')}</Link>
        </li>
        <li className={originalPath === `/investor/news` ? `uk-active` : undefined}>
          <Link to="/investor/news">{t('News')}</Link>
        </li>
      </ul>

      <ul
        className="uk-navbar-nav uk-visible@m"
        style={originalPath.split('/')[1] === `investor` ? { display: `none` } : undefined}
      >
        <li className={originalPath === `/` ? `uk-active` : undefined}>
          <Link to="/">{t('Home')}</Link>
        </li>

        <li className={originalPath === `/services/products` ? `uk-active` : undefined}>
          <a href="#">{t('Apps')}</a>
          <div
            ref={appMenu}
            id="app-menu"
            className="uk-navbar-dropdown uk-navbar-dropdown-width-4"
            uk-drop="boundary: !nav; boundary-align: true; pos: bottom-justify;"
          >
            <div id="app-menu-column" className="uk-grid-collapse uk-child-width-1-4" uk-grid="">
              <div>
                <div onClick={onLinkClicked} className="menu-list-container">
                  <GatsbyImage
                    image={data.businessCommunicationIcon.childImageSharp.gatsbyImageData}
                    className="uk-margin-small-top uk-margin-small-right"
                    alt={'business communication logo'}
                    style={{ overflow: 'unset' }}
                  />
                  <div>
                    <h5 className="uk-margin-small-top uk-margin-small-bottom">{t('Business Communication')}</h5>
                    <hr className="uk-margin-small" />
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li className={originalPath === `/products/jascloud` ? `uk-active` : undefined}>
                        <Link className="uk-text-top uk-navbar-dropdown-close" to="/products/jascloud">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.jascloudLogo.childImageSharp.gatsbyImageData}
                                alt={'jascloud logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-preserve-color uk-margin-small-bottom">Jascloud</div>
                              <div className="uk-comment-meta">{t('Jascloud Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className={originalPath === `/products/whatsapp-business` ? `uk-active` : undefined}>
                        <Link to="/products/whatsapp-business">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.whatsappBusinessLogo.childImageSharp.gatsbyImageData}
                                alt={'jasmeet logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">Whatsapp Business API</div>
                              <div className="uk-comment-meta">{t('Whatsapp Business Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className={originalPath === `/products/jasmeet` ? `uk-active` : undefined}>
                        <Link to="/products/jasmeet">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.jasmeetLogo.childImageSharp.gatsbyImageData}
                                alt={'jasmeet logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">Jasmeet</div>
                              <div className="uk-comment-meta">{t('Jasmeet Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className={originalPath === `/products/jasgrid` ? `uk-active` : undefined}>
                        <ul id="core-features" className="uk-nav-default uk-nav-parent-icon uk-margin-top" uk-nav="">
                          <li className="uk-nav-header">{t('Main Features')}:</li>
                          <li className="uk-active">
                            <Link className="uk-navbar-dropdown-close" to="/products/whatsapp-business">
                              {t('Messages')}
                              <small>{t('Messages Description')}</small>
                            </Link>
                          </li>
                          <li>
                            <Link className="uk-navbar-dropdown-close" to="/products/jascloud#voip">
                              {t('Phone')}
                              <small>{t('Phone Description')}</small>
                            </Link>
                          </li>
                          <li>
                            <Link className="uk-navbar-dropdown-close" to="/products/jasmeet">
                              {t('Video')}
                              <small>{t('Video Description')}</small>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div onClick={onLinkClicked} className="menu-list-container">
                  <GatsbyImage
                    image={data.customerExperienceIcon.childImageSharp.gatsbyImageData}
                    className="uk-margin-small-top uk-margin-small-right"
                    alt={'business communication logo'}
                    style={{ overflow: 'unset' }}
                  />
                  <div>
                    <h5 className="uk-margin-small-top uk-margin-small-bottom">{t('Customer Experience')}</h5>
                    <hr className="uk-margin-small" />
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li className={originalPath === `/products/tos-omnichannel` ? `uk-active` : undefined}>
                        <Link to="/products/tos-omnichannel">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.omniLogo.childImageSharp.gatsbyImageData}
                                alt={'omni channel logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">TOSchat</div>
                              <div className="uk-comment-meta">{t('Omni Channel Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li className={originalPath === `/products/tos-omnichannel#crm` ? `uk-active` : undefined}>
                        <Link to="/products/jascloud#crm">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage image={data.crmLogo.childImageSharp.gatsbyImageData} alt={'crm logo'} />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">CRM</div>
                              <div className="uk-comment-meta">{t('CRM Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li
                        className={
                          originalPath === `/products/tos-omnichannel#contact-center` ? `uk-active` : undefined
                        }
                      >
                        <Link to="/products/jascloud#contact-center">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.marketplaceLogo.childImageSharp.gatsbyImageData}
                                alt={'contact center logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">Contact Center</div>
                              <div className="uk-comment-meta">{t('Contact-Center Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <ul id="core-features" className="uk-nav-default uk-nav-parent-icon uk-margin-top" uk-nav="">
                          <li className="uk-nav-header">{t('Main Features')}:</li>
                          <li className="uk-active">
                            <Link to="/products/tos-omnichannel#omni-channel">
                              {t('Social Media')}
                              <small>{t('Social Media Description')}</small>
                            </Link>
                          </li>
                          <li>
                            <Link to="/products/tos-omnichannel#chat-pannel">
                              {t('Chat')} <small>{t('Chat Description')}</small>
                            </Link>
                          </li>
                          <li>
                            <Link to="/products/jascloud#crm">
                              {t('Ticketing')}
                              <small>{t('Ticketing Description')}</small>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div onClick={onLinkClicked} className="menu-list-container">
                  <GatsbyImage
                    image={data.governmentServiceIcon.childImageSharp.gatsbyImageData}
                    className="uk-margin-small-top uk-margin-small-right"
                    alt={'business communication logo'}
                    style={{ overflow: 'unset' }}
                  />
                  <div>
                    <h5 className="uk-margin-small-top uk-margin-small-bottom">{t('Government Services')}</h5>
                    <hr className="uk-margin-small" />
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li className={originalPath === `/products/emergency-call` ? `uk-active` : undefined}>
                        <Link to="/products/emergency-call">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.saktiLogo.childImageSharp.gatsbyImageData}
                                alt={'112 Emergency logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">112 Emergency Service</div>
                              <div className="uk-comment-meta">{t('Emergency Call Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li>
                        <ul id="core-features" className="uk-nav-default uk-nav-parent-icon uk-margin-top" uk-nav="">
                          <li className="uk-nav-header">{t('Other Services')}:</li>
                          <li className="uk-active">
                            <Link to="/products/business-network">
                              {t('Business Network')}
                              <small>{t('Business Network Description')}</small>
                            </Link>
                          </li>
                          <li>
                            <Link to="/products/business-network#cyber-security">
                              {t('Cyber Security')}
                              <small>{t('Cyber Security Description')}</small>
                            </Link>
                          </li>
                          <li>
                            <Link to="/products/business-outsourcing">
                              {t('Business Outsourcing')}
                              <small>{t('Business Outsourcing Description')}</small>
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/products/jasgrid">
                              {t('Customization')}
                              <small>{t('Customization Description')}</small>
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div onClick={onLinkClicked} className="menu-list-container">
                  <GatsbyImage
                    image={data.ecosystemIcon.childImageSharp.gatsbyImageData}
                    className="uk-margin-small-top uk-margin-small-right"
                    alt={'business communication logo'}
                    style={{ overflow: 'unset' }}
                  />
                  <div>
                    <h5 className="uk-margin-small-top uk-margin-small-bottom">{t('Ecosystem')}</h5>
                    <hr className="uk-margin-small" />
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li className={originalPath === `/products/jasgrid#marketplace` ? `uk-active` : undefined}>
                        <Link to="/products/jasgrid#marketplace">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.marketplaceLogo.childImageSharp.gatsbyImageData}
                                alt={'jasnita marketplace logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">Jast Marketplace</div>
                              <div className="uk-comment-meta">{t('Marketplace Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li className={originalPath === `/products/tos-omnichannel` ? `uk-active` : undefined}>
                        <Link to="/developer-portal">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.developerLogo.childImageSharp.gatsbyImageData}
                                alt={'jasnita developer logo'}
                              />
                            </div>
                            <div className="uk-width-expand ">
                              <div className="uk-h4 uk-margin-small-bottom">Developer Portal</div>
                              <div className="uk-comment-meta">{t('Developer Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className={originalPath === `/products/jasgrid` ? `uk-active` : undefined}>
                        <Link to="/products/jasgrid">
                          <div>
                            <div className="uk-width-auto">
                              <GatsbyImage
                                image={data.jasgridLogo.childImageSharp.gatsbyImageData}
                                alt={'jasgrid logo'}
                              />
                            </div>
                            <div className="uk-width-expand">
                              <div className="uk-h4 uk-margin-small-bottom">Jasgrid Smart City</div>
                              <div className="uk-comment-meta">{t('Jasgrid Description')}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className={originalPath === `/solutions` ? `uk-active` : undefined}>
          <Link to="/solutions">{t('Solutions')}</Link>
        </li>

        <li className={originalPath === `/about` ? `uk-active` : undefined}>
          <Link to="/about">{t('About')}</Link>
          <div className="uk-navbar-dropdown">
            <ul className="uk-nav uk-navbar-dropdown-nav">
              <li className={location.hash === '#history' ? 'uk-active' : undefined}>
                <Link to="/about#history">{content.history.title}</Link>
              </li>
              <li className={location.hash === '#vision-and-mission' ? 'uk-active' : undefined}>
                <Link to="/about#vision-and-mission">{content.vision_and_mission.title}</Link>
              </li>
              <li className={location.hash === '#organization-structure' ? 'uk-active' : undefined}>
                <Link to="/about#organization-structure">{content.organization_structure.title}</Link>
              </li>
              <li className={location.hash === '#board-of-comissioners' ? 'uk-active' : undefined}>
                <Link to="/about#board-of-comissioners">{content.board_of_comissioners.title}</Link>
              </li>
              <li className={location.hash === '#board-of-directors' ? 'uk-active' : undefined}>
                <Link to="/about#board-of-directors">{content.board_of_directors.title}</Link>
              </li>
              <li className={location.hash === '#supporting-institution' ? 'uk-active' : undefined}>
                <Link to="/about#supporting-institution">{content.supporting_institution.title}</Link>
              </li>
            </ul>
          </div>
        </li>

        <li className={originalPath === `/blog` ? `uk-active` : undefined}>
          <Link to="/blog">{t('Blog')}</Link>
        </li>
      </ul>
    </>
  )
}
