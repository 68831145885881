// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-become-customer-tsx": () => import("./../../../src/pages/become-customer.tsx" /* webpackChunkName: "component---src-pages-become-customer-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-developer-portal-tsx": () => import("./../../../src/pages/developer-portal.tsx" /* webpackChunkName: "component---src-pages-developer-portal-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-about-tsx": () => import("./../../../src/pages/investor/about.tsx" /* webpackChunkName: "component---src-pages-investor-about-tsx" */),
  "component---src-pages-investor-index-tsx": () => import("./../../../src/pages/investor/index.tsx" /* webpackChunkName: "component---src-pages-investor-index-tsx" */),
  "component---src-pages-investor-report-tsx": () => import("./../../../src/pages/investor/report.tsx" /* webpackChunkName: "component---src-pages-investor-report-tsx" */),
  "component---src-pages-investor-rups-tsx": () => import("./../../../src/pages/investor/rups.tsx" /* webpackChunkName: "component---src-pages-investor-rups-tsx" */),
  "component---src-pages-investor-stock-tsx": () => import("./../../../src/pages/investor/stock.tsx" /* webpackChunkName: "component---src-pages-investor-stock-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-business-network-tsx": () => import("./../../../src/pages/products/business-network.tsx" /* webpackChunkName: "component---src-pages-products-business-network-tsx" */),
  "component---src-pages-products-business-outsourcing-tsx": () => import("./../../../src/pages/products/business-outsourcing.tsx" /* webpackChunkName: "component---src-pages-products-business-outsourcing-tsx" */),
  "component---src-pages-products-emergency-call-tsx": () => import("./../../../src/pages/products/emergency-call.tsx" /* webpackChunkName: "component---src-pages-products-emergency-call-tsx" */),
  "component---src-pages-products-jascloud-tsx": () => import("./../../../src/pages/products/jascloud.tsx" /* webpackChunkName: "component---src-pages-products-jascloud-tsx" */),
  "component---src-pages-products-jasgrid-tsx": () => import("./../../../src/pages/products/jasgrid.tsx" /* webpackChunkName: "component---src-pages-products-jasgrid-tsx" */),
  "component---src-pages-products-jasmeet-tsx": () => import("./../../../src/pages/products/jasmeet.tsx" /* webpackChunkName: "component---src-pages-products-jasmeet-tsx" */),
  "component---src-pages-products-tos-omnichannel-tsx": () => import("./../../../src/pages/products/tos-omnichannel.tsx" /* webpackChunkName: "component---src-pages-products-tos-omnichannel-tsx" */),
  "component---src-pages-products-whatsapp-business-tsx": () => import("./../../../src/pages/products/whatsapp-business.tsx" /* webpackChunkName: "component---src-pages-products-whatsapp-business-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-term-and-condition-tsx": () => import("./../../../src/pages/term-and-condition.tsx" /* webpackChunkName: "component---src-pages-term-and-condition-tsx" */),
  "component---src-templates-blog-detail-tsx": () => import("./../../../src/templates/blog-detail.tsx" /* webpackChunkName: "component---src-templates-blog-detail-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/news-detail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

